import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mini-stats-gb',
  templateUrl: './mini-stats-gb.component.html',
  styleUrls: ['./mini-stats-gb.component.scss']
})
export class MiniStatsGbComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() color: string;
  @Input() value: number;
  @Input() type: string;
  @Input() details_ios: number;
  @Input() details_android: number;
  displayValue: number = 0;
  displayAndroid: number = 0;
  displayIos: number = 0;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.animateData();
  }

  isExtSvg(name: string) {
    let ext = this.getFileExtension(name);
    return (ext && ext == "svg") || false;
  }

  animateData() {
    const duration = 1000;
    const steps = 50; 
    const stepAndroid = this.details_android / steps;
    const stepIos = this.details_ios / steps;
    const stepValue = this.value / steps;
    const stepDuration = duration / steps;

    let currentValue = 0;
    let currentIos = 0;
    let currentAndroid = 0;
    const timer = setInterval(() => {
      currentValue += stepValue;
      currentAndroid += stepAndroid;
      currentIos += stepIos;
      this.displayAndroid = Math.min(Math.round(currentAndroid), this.details_android);
      this.displayIos = Math.min(Math.round(currentIos), this.details_ios);
      this.displayValue = Math.min(Math.round(currentValue), this.value);
      if (this.displayAndroid >= this.details_android && this.displayIos >= this.details_ios && this.displayValue >= this.value) {
        clearInterval(timer);
      }
    }, stepDuration);
  }

  getFileExtension(file: string) {
    var regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    var extension = file.match(regexp);
    return extension && extension[1];
  }

}
