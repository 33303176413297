import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild, Input } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ApiService } from "../../services/api.service";
import { defaultAnimations } from "../../shared/animations/default-animations";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-back-office-admin-upload',
  templateUrl: './back-office-admin-upload.component.html',
  styleUrls: ['./back-office-admin-upload.component.scss'],
  animations: defaultAnimations
})
export class BackOfficeAdminUploadComponent implements OnInit {
  @Input() userId: any;
  
  @ViewChild('tableWrapper', { static: false }) tableWrapper;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  public readonly LAYOUT = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;
  customText: any = window['customText'][window['language']];
  public items: any[];
  private currentComponentWidth: number;
  public pageNumber: number = 0;
  public per_page:number = 15;
  public totalElements: number = 0;

  ngxDatatableMessage = {
    selectedMessage: 'selected',
    totalMessage: 'total',
    emptyMessage: `Aucun résultat`
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    setTimeout(() => {
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {

        if (this.items)
          this.items = [...this.items]
      }
    }, 200);
  }

  constructor(
    private apiService: ApiService,
    public changeDetectorRef: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { 
    this.generateBreakPoints();
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  generateBreakPoints() {
    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = (index > 0) ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) { return; }
          this.layout = maxWidth;

        });
    });
  }
  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getUploadUserHistory(this.pageNumber);
  }

  getUploadUserHistory(pageNumber) {

    this.apiService.getEveryoneUploadHistory(this.userId, pageNumber, this.per_page)
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.totalElements = reponse['files']['totalCount'];
            this.items = reponse['files']['paginatedResults'];
          }
        },
        error => {
          //this.loading = false;
        },
        () => {
          //this.loading = false;
        }
      );
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  removeItem(row) {
    let i = this.items.indexOf(row);
    this.items.splice(i, 1);
    if (this.items)
      this.items = [...this.items]
  }

  deleteItem(row) {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: this.customText.admin_files_delete_confirmation.text,
        btnOk: this.customText.admin_files_delete_confirmation.btnOk,
        btnCancel: this.customText.admin_files_delete_confirmation.btnCancel
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.apiService.adminDeleteFiles([row.location])
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false && reponse['file']['deletedCount'] >= 1) {
                this.removeItem(row);
                this.totalElements--;
                this.snackBar.open(this.customText.snackBar_admin_file_deleted.text, "", {
                  duration: this.customText.snackBar_admin_file_deleted.duration,
                  verticalPosition: this.customText.snackBar_admin_file_deleted.verticalPosition,
                  horizontalPosition: this.customText.snackBar_admin_file_deleted.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error deleting file");
              }
            },
            error => {
              this.displayErrorSnackbar(error);
            },
            () => {

            }
          )
      }
    });
  }
}
