import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { AppLoaderService } from '../../../../services/app-loader/app-loader.service';
import { AlertModalComponent } from '../../../../shared/components/alert-modal/alert-modal.component';
import { MatDialog } from '@angular/material';
import { HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';



interface FileDetails {
  file: File;
  extension: string;
  name: string;
}


@Component({
  selector: 'app-publish-upload',
  templateUrl: './publish-upload.component.html',
  styleUrls: ['./publish-upload.component.scss']
})
export class PublishUploadComponent implements OnInit {
  @Input() countySelected: string;
  @Input() dateBegin: Date;
  @Input() dateEnd: Date;
  @Output() uploadEvent = new EventEmitter<any>();

  maxUpload: number = 10;
  customText: any = window['customText'][window['language']];

  uploadFiles: FileDetails[] = [];
  uploadInProgress: boolean = false;
  uploadProgress: number = 0;


  constructor(
    private apiService: ApiService,
    private loader: AppLoaderService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  addFile(event) {

    let FILES: FileList;
    if (event.target)
      FILES = (event.target as HTMLInputElement).files;
    else
      FILES = event;

    if (this.uploadFiles.length + FILES.length >= this.maxUpload) {
      this.dialog.open(AlertModalComponent, {
        maxWidth: '350px',
        data: {
          message: `Un maximum de ${this.maxUpload} fichiers est permis`, btnOk: "Ok",
          icon: { maticon: "warning", style: { 'color': 'indianred', 'height': '34px', 'width': '34px', 'font-size': '34px' } }
        }
      });
      return;
    }

    Array.from(FILES).forEach(file => {
      const extension = file.name.split('.').pop().toLowerCase();
      const name = file.name.split('.').shift();
      this.uploadFiles.push({
        file: file,
        name: name ? name : '',
        extension: extension ? extension : '',
      });
    });

  }

  formatFileSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;

    if (size >= mb) {
      return (size / mb).toFixed(2) + ' Mo';
    } else {
      return (size / kb).toFixed(2) + ' Ko';
    }
  }

  removeFile(index: number) {
    this.uploadFiles.splice(index, 1);
  }

  updateProgress(event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      const percentDone = Math.round(100 * event.loaded / event.total);
      this.uploadProgress = percentDone;
    }
  }


  upload() {
    var filesForm = new FormData();

    this.uploadInProgress = true;
    this.uploadProgress = 0;

    filesForm.append("countySelected", this.countySelected);
    filesForm.append("dateBegin", this.dateBegin.toUTCString());
    filesForm.append("dateEnd", this.dateEnd.toUTCString());

    this.uploadFiles.forEach(fileDetails => {
      filesForm.append("files", fileDetails.file);
    });


    this.apiService.upload(filesForm)
      .subscribe(
        (event: HttpEvent<any>) => {
          this.updateProgress(event);
          if (event.type === HttpEventType.Response) {
            this.uploadEvent.emit(event.body);
            this.uploadFiles = [];
            this.uploadInProgress = false;
            this.uploadProgress = 0;
          }
        },
        () => {
          this.uploadInProgress = false;
          this.uploadProgress = 0;
        }
      );

  }

}
