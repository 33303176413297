import { Directive, AfterContentChecked, TemplateRef, ViewContainerRef, Input, ChangeDetectorRef } from '@angular/core';

@Directive({ selector: '[ngxResponsiveColumn]' })
export class NgxResponsiveColumnDirective implements AfterContentChecked {

  private _visible = false;

  @Input('ngxResponsiveColumn') threshold: number;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    public changeDetectorRef: ChangeDetectorRef) {

  }

  ngAfterContentChecked() {

    setTimeout(() => {
      if (window.innerWidth >= 1480) {
        this.show();
      } else if (window.innerWidth >= 1240 && window.innerWidth <= 1479) {
        if (this.threshold === 1) {
          this.hide();
        } else {
          this.show();
        }
      } else if (window.innerWidth >= 1000 && window.innerWidth <= 1239) {
        if (this.threshold <= 2) {
          this.hide();
        } else {
          this.show();
        }
      } else if (window.innerWidth <= 999) {
        if (this.threshold <= 3) {
          this.hide();
        }
      }
  });

   //this.changeDetectorRef.detectChanges();
  }

  private show() {
    if (this._visible) {
      return;
    }
    this._viewContainer.createEmbeddedView(this._templateRef);
    this._visible = true;
  }

  private hide() {
    if (!this._visible) {
      return;
    }
    this._viewContainer.clear();
    this._visible = false;
  }

}