import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { MatButton, MatInput } from '@angular/material';

export class DisableMatViewElements implements AfterViewInit{

    @ViewChildren(MatInput) inputs:QueryList<any>;
    @ViewChildren(MatButton) buttons:QueryList<any>;

    constructor() {
    }

    ngAfterViewInit() {
    }

    disableAll(){
        const arrayOfQuery = [...this.inputs.toArray(), ...this.buttons.toArray()];
        arrayOfQuery.forEach(instance => { 
            let ref = instance;

            if(instance._elementRef && instance._elementRef.nativeElement)
                ref = instance._elementRef.nativeElement;
            else if (instance.nativeElement)
                ref = instance.nativeElement;

            ref.disabled = true;
        } );
    }
    enableAll(){
        const arrayOfQuery = [...this.inputs.toArray(), ...this.buttons.toArray()];
        arrayOfQuery.forEach(instance => { 
            let ref = instance;

            if(instance._elementRef && instance._elementRef.nativeElement)
                ref = instance._elementRef.nativeElement;
            else if (instance.nativeElement)
                ref = instance.nativeElement;

            ref.disabled = false;
        } );
    }

}
