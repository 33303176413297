import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from "../../../services/api.service";
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { AppLoaderService } from '../../../services/app-loader/app-loader.service';
import * as moment from 'moment';
import * as localization from 'moment/locale/fr';
moment.locale('fr', localization);

@Component({
  selector: 'app-back-office-advertising-management-container',
  templateUrl: './back-office-advertising-management-container.component.html',
  styleUrls: ['./back-office-advertising-management-container.component.scss']
})
export class BackOfficeAdvertisingManagementContainerComponent implements OnInit {
  @Input() resolved;

  customText: any = window['customText'][window['language']];
  campaign_types: any = this.customText.campaign_type;
  campaign_typeSelected: string = "open_app";
  sortedCampaignTypes: any[];
  sortedCounties: any[];

  counties: any = this.customText.counties;
  countySelected: String = "mart";
  dateBegin: Date;
  dateEnd: Date;
  message: string
  modifiedInvalidDates: any;

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loader: AppLoaderService
  ) { }

  ngOnInit() {

    this.modifiedInvalidDates = this.resolved['dates'].filter((el: any) => el && el.message && (new Date(el.campainDateBegin).getMonth() === new Date().getMonth() || new Date(el.campainDateBegin).getMonth() === new Date().getMonth() + 1)).map((el: any) => {
      return {
        campainDateBegin: this.dateFormater(new Date(el.campainDateBegin), '/'),
        campaignDateEnd: this.dateFormater(new Date(el.campaignDateEnd), '/'),
        message: el.message
      }
    });
    this.sortCampaignTypes();
    this.sortCounty();
    console.log("this.modifiedInvalidDates: ", this.modifiedInvalidDates);
  }

  sortCampaignTypes() {
    const order = ['open_app', 'banner_home', 'catalog'];
    this.sortedCampaignTypes = order.map(key => ({
      key,
      ...this.campaign_types[key]
    }));
  }

  sortCounty() {
    const order = ['mart', 'guad', 'guya', 'maba'];
    this.sortedCounties = order.map(key => ({
      key,
      ...this.counties[key]
    }));
  }


  dateFormater = (date, separator) => {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return day + separator + month + separator + year;
  }

  onCampaignTypeChangedEvent(checkResult) {
    this.campaign_typeSelected = checkResult;
  }

  onCountieChangedEvent(checkResult) {
    this.countySelected = checkResult;
  }
  convertDateBegin(dateBegin) {
    let date = new Date(Date.UTC(dateBegin.getFullYear(), dateBegin.getMonth(), dateBegin.getDate(), 0, 0, 0));
    return date;
  }
  convertDateEnd(dateEnd) {
    let date = new Date(Date.UTC(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate(), 23, 59, 59));
    return date;
  }

  onDateRangeChange(event) {
    if (event) {

      this.dateBegin = event.startDate == null ? null : event.startDate.toDate();
      this.dateEnd = event.endDate == null ? null : event.endDate.toDate();
      //this.dateBegin = this.convertDateBegin(event.startDate.toDate());
      //this.dateEnd = this.convertDateEnd(event.endDate.toDate());
    }
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  onChange(e) {
    this.message = e.target.value;
  }

  onClickReservation() {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: this.customText.advertising_management_send_confirmation.text,
        btnOk: this.customText.advertising_management_send_confirmation.btnOk,
        btnCancel: this.customText.advertising_management_send_confirmation.btnCancel
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.loader.open("Veuillez patienter");
        this.apiService.reservateCampaign(this.campaign_typeSelected, this.countySelected, this.dateBegin, this.dateEnd, this.message)
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false && reponse['campaign']) {

                this.snackBar.open(this.customText.snackBar_reservation_sent.text, "", {
                  duration: this.customText.snackBar_reservation_sent.duration,
                  verticalPosition: this.customText.snackBar_reservation_sent.verticalPosition,
                  horizontalPosition: this.customText.snackBar_reservation_sent.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error reservating");
              }
            },
            error => {
              this.loader.close();
              this.displayErrorSnackbar(error);
            },
            () => {
              this.loader.close();
            }
          )
      }
    });

  }


}
