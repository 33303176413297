import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-device ',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
    @Input() defaultValue: String;
    @Input() ListOfPossibleSelection: Array<any>;
    @Input() disabled: Boolean;
    @Output() imageRadioChangedEvent = new EventEmitter<any>();

    public selected: String;
    public ListOfPossibleSelectionCount: number;
    public radioName: String = "radio-button-" + this.makeId(6);
    constructor() { }

    ngOnInit() {
        this.selected = this.defaultValue;
        this.ListOfPossibleSelectionCount = Object.keys(this.ListOfPossibleSelection).length;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['defaultValue']) {
            let value = changes['defaultValue'].currentValue;
            this.selected = value;
        }
    }

    isIcon(fileName) {
        let fileSplit = fileName.split('.');
        let fileExt = '';
        if (fileSplit.length > 1)
            fileExt = fileSplit[fileSplit.length - 1];

        if (fileExt.length)
            return false;
        else
            return true;
    }

    makeId(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    select(value) {
        this.selected = value;
    }

}
