import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-multi-stats-board",
  templateUrl: "./multi-stats-board.component.html",
  styleUrls: ["./multi-stats-board.component.scss"],
})
export class MultiStatsBoardComponent implements OnInit {
  @Input() firstHalfList: Array<any>;
  @Input() secondHalfList: Array<any>;

  constructor() {}

  ngOnInit() {}
}
