import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-images-radiobutton-item-img',
  templateUrl: './images-radiobutton-item-img.component.html',
  styleUrls: ['./images-radiobutton-item-img.component.scss']
})
export class ImagesRadiobuttonItemImgComponent implements OnInit {
  @Input() label: String;
  @Input() value: String;
  @Input() image: String;
  @Input() selected: Boolean;
  constructor() { }

  ngOnInit() {
  }


}
