import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { OverlayContainer } from '@angular/cdk/overlay';
import { StateService, TransitionService, Transition } from '@uirouter/core'
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ileco-client';
  customText: any = window['customText'][window['language']];
  spinnerConfig: any = window['spinner'];
  theme$ = window['theme'];

  //svgIcons: string[] = ['icon_sprt', 'icon_upld', 'icon_sts', 'icon_cal']

  svgIcons: string[] = ['icon_app', 'icon_cal', 'icon_dwnl', 'icon_dwnl02', 'icon_mgz', 'icon_mny', 'icon_ntfc',
    'icon_sprt', 'icon_stpwtch', 'icon_sts', 'icon_trnd', 'icon_upld', 'icon_vues', 'map01', 'map02', 'map03', 'map04',
    'catalogue', 'lancement_ecran', 'mail', 'profil_groupe', 'profil', 'certified_badge', 'map_ilecoshopldpi', 'location_ilecoshop', 'verify', 'notification', 'reading-book', 'responsibility',
    'eye', 'ad-pop-up', 'budget', 'impression', 'click', 'landingspage', 'lectures-pb', 'clicks-pb', 'banners', 'lectures_retour',
    'lectures', 'engagement', 'interactions', 'cout_lecteur', 'history', 'search_gray', 'promotions', 'calendar', 'eyes', 'geo',
    'file-download', 'banner', 'ouverture', 'catalogue', 'ouverture-checked', 'banner-checked', 'catalogue-checked', 'cloud-upload', 'info-upload', 'file-upload', 'delete-file-upload',
    'android', 'apple', 'all-device', 'tablette', 'lectures_retour_2', 'audience', 'arrow-up', 'download-counter', 'icon_usr'

  ];

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public overlayContainer: OverlayContainer,
    public transitionService: TransitionService,
    private titleService: Title) {
    this.registerSvgIcons();
    this.applyTheme(this.theme$);
    this.transitionService.onSuccess({}, (transition: Transition) => {
      let substate = transition.to();

      if (substate && substate.parent) {
        if (substate.parent == "BackOfficeUser")
          this.titleService.setTitle("ILÉCO");
        else if (substate.parent == "BackOfficeAdmin")
          this.titleService.setTitle("ILÉCO - BACKOFFICE");
      } else
        this.titleService.setTitle("ILÉCO");

    });
  }

  applyTheme(theme: string) {
    this.overlayContainer.getContainerElement().classList.add(theme);
  }
  registerSvgIcons() {
    this.svgIcons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/svg/${icon}.svg`));
    });
  }


}
