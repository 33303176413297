import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import * as L from 'leaflet';

import { ApiService } from "../../../../services/api.service";
import { responsivePopup } from 'leaflet-responsive-popup';

declare var HeatmapOverlay;
const per_page = 10000;

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss']
})
export class HeatMapComponent implements OnInit {
  @Input() catalogOpened;
  @Input() autoCenter;

  loading: boolean = true;
  subscription: any = null;
  companiesSubscription: any = null;
  //catalogOpenedPosition: any = [];
  map: any = null;
  heatmapLayer = new HeatmapOverlay({
    "radius": 15,
    "maxOpacity": .5, 
    // scales the radius based on map zoom
    "scaleRadius": false, 
    // if set to false the heatmap uses the global maximum for colorization
    // if activated: uses the data maximum within the current map boundaries 
    //   (there will always be a red spot with useLocalExtremas true)
    "useLocalExtrema": true,
    // which field name in your data represents the latitude - default "lat"
    latField: 'lat',
    // which field name in your data represents the longitude - default "lng"
    lngField: 'lng',
    // which field name in your data represents the data value - default "value"
    valueField: 'count',
    blur: 0.95,
    gradient: { 
    '.5': 'yellow',
    '.8': 'orange',
    '.95': 'red'
    }
  });

  displayMarkers: boolean = true;
  markersList: any = [];
  markerClusterGroup: L.MarkerClusterGroup;
	markerClusterData: L.Marker[] = [];
	markerClusterOptions: L.MarkerClusterGroupOptions = {
    maxClusterRadius: 40,
    animate: false,
    iconCreateFunction: function(cluster) {
      return L.divIcon({ 
        html: String(cluster.getChildCount()), 
        className: 'mycluster', 
        iconSize: null 
    });
    }
  }
  

  /*
  LAYER_OSM = {
		id: 'openstreetmap',
		name: 'Open Street Map',
		enabled: false,
		layer: [ 
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 14,
			attribution: 'Open Street Map'
		  }),
      this.heatmapLayer
    ]
  };

 
	baseLayers = {
		'Open Street Map': this.LAYER_OSM.layer
	};
*/
  //layersControlOptions = { position: 'topright', attributionControl: false };
  options = {
		layers: [ 
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 18,
			attribution: 'Open Street Map'
		  }),
      this.heatmapLayer
    ],
		zoom: 3,
		center: L.latLng([46.879966, -121.726909])
  };


  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.retrieveCompanies(0, 300);
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (changes['catalogOpened'] && this.catalogOpened) {
      this.startPopulateMap();
    }
  }

	onMapReady(map) {
    this.map = map;
  }
  
  retrieveCompanies(offset = 0, limit = 100){
    this.companiesSubscription = this.apiService.getCompanies(offset, limit)
      .subscribe(
        reponse => {
          if(reponse && reponse['error'] == false && reponse['companies'] && reponse['companies'].entities && reponse['companies'].entities.length) {
            this.markersList.push(...reponse['companies'].entities);
            if(limit == reponse['companies'].entities.length)
              this.retrieveCompanies(offset + limit, limit);
            else {
              this.populateCompanies(this.markersList);
            }

            //console.log(reponse['companies'].entities);
          }
        }
      )
  }

  populateCompanies(dataToAdd: any = []){
    this.markerClusterData = this.generateMarkers(dataToAdd);
  }

  generateMarkers(dataPoi): any{
		const data: L.Marker[] = [];
	  
		dataPoi.forEach(item => {
			var icon = null;
			let iconExtraMarker = item.company_sign_logo_path;

			if(iconExtraMarker){
				icon = L.divIcon({
          className: 'custom-div-icon',
          html: `<div style='background-color: ${item.affiliate_group_hex_color};' class='marker-pin'></div> <img src='${iconExtraMarker}' />`,
          iconSize: [28, 28],
          iconAnchor: [14, 40],
          popupAnchor: [15, 15],
				});
			} else {
				icon = L.icon({
					iconUrl: 'assets/marker-icon.png',
					shadowUrl: 'assets/marker-shadow.png'
				});
			}

      let address = item.full_address.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>')
      let aPopup = responsivePopup().setContent(`<span class="popup-title">${item.company_commercial_name}</span><br><br><span>${address}`);
			data.push(
				L.marker([item.lat, item.lng], { icon })
					.bindPopup(aPopup).openPopup()
        );
		});

		return data;
	}

  markerClusterReady(group: L.MarkerClusterGroup) {
		this.markerClusterGroup = group;
	}


  retrieveCatalogOpenedPosition(catalogOpened: any, page = 1){
    this.subscription = this.apiService.getOpenedCatalogPositions(catalogOpened.catalogId, page, per_page)
      .subscribe(
        reponse => {
          if(reponse && reponse['error'] == false && reponse['catalogOpenedPosition'] && reponse['catalogOpenedPosition'].length) {
            this.populateMap(reponse['catalogOpenedPosition'], page == 1);
            if(per_page == reponse['catalogOpenedPosition'].length)
              this.retrieveCatalogOpenedPosition(catalogOpened, page + 1 || 1);
            else {
              this.loading = false;
              this.subscription = null;
            }
          } else {
            this.loading = false;
            this.subscription = null;
          }
        }
      );
  }

  startPopulateMap(){
    this.stopPopulateMap();
    this.loading = true;
    this.eraseMap();
    this.retrieveCatalogOpenedPosition(this.catalogOpened);
  }

  stopPopulateMap(){
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  eraseMap(){
    let data = { data: [] }
    this.heatmapLayer.setData(data);
  }

  populateMap(dataToAdd: any = [], setData: boolean = false){
    let data = { data: [] }
    if(dataToAdd && dataToAdd.length){
      dataToAdd.forEach(element => {
        data.data.push({ lat: element.coordinates[1], lng: element.coordinates[0], count: 1 });
      });
    }
    
    if(setData)
      this.heatmapLayer.setData(data);
    else if(data.data.length){
      this.heatmapLayer.addData(data.data);
    }

    if(this.autoCenter)
      this.fitBounds(data, 3);
      
  }

  centerOnPoint(coords, zoom){
    if(this.map)
      this.map.setView(coords, zoom)
  }

  generateRandomData(len) {
    var max = 100;
    var min = 1;
    var maxX = document.body.clientWidth;
    var maxY = document.body.clientHeight;
    var data = [];
    while (len--) {
      data.push({
        lat: ((Math.random() * maxX) >> 0),
        lng: ((Math.random() * maxY) >> 0),
        count: ((Math.random() * max + min) >> 0)
      });
    }
    return {
      max: max,
      data: data
    }
  };

  averageGeolocation(coords) {

    if (coords.length === 1) {
      return coords[0];
    }
  
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
  
    for (let coord of coords) {
      let latitude = coord.lat * Math.PI / 180;
      let longitude = coord.lng * Math.PI / 180;
  
      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }
  
    let total = coords.length;
  
    x = x / total;
    y = y / total;
    z = z / total;
  
    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);
  
    return {
      lat: centralLatitude * 180 / Math.PI,
      lng: centralLongitude * 180 / Math.PI
    };
  }

  fitBounds(points, zoom): void {
    let center = this.averageGeolocation(points.data);
    let coords =  [ center.lat, center.lng ];
    if(this.map)
      this.map.setView(coords, zoom)
  }

  onClickDisplayMarkers(){
    this.displayMarkers = !this.displayMarkers;
  }
}
