import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//FontAwesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';

//Custom FontAwesome icons
import { faAndroid } from './shared/icons/faAndroid';

//Material angular
import {
  MatProgressBarModule, MatInputModule, MatCardModule, MatSnackBarModule, MatButtonModule,
  MatIconModule, MatDialogModule, MatToolbarModule, MatSidenavModule, MatListModule, MatMenuModule, MatRadioModule, MatDividerModule,
  MatChipsModule, MatSlideToggleModule, MatCheckboxModule, MatProgressSpinnerModule, MatSelectModule, MatFormFieldModule, MatTabsModule

} from '@angular/material';



//Components
import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { AlertModalComponent } from './shared/components/alert-modal/alert-modal.component';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { SelectModalResponsablesComponent } from './shared/components/select/select-modal-responsables.component';


//Validators
import { OpenCatalogIdValidator } from './helpers/openCatalogIdValidator';

//Pipes
import { removeExtPipe } from './pipes/remove-ext.pipe'
import { PluralizePipe } from './pipes/pluralize.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { DurationPipe } from './pipes/duration.pipe';

//Helpers
import { JwtInterceptor } from './helpers/jwt.interceptor';

//others modules
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Ng2FittextModule } from "ng2-fittext";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MaterialFileInputModule } from 'ngx-material-file-input';
//import { NgxMaskModule } from 'ngx-mask'
import { InputFileConfig, InputFileModule } from 'ngx-input-file';
import { ChartsModule, ThemeService } from 'ng2-charts';

//Directives
import { NgxResponsiveColumnDirective } from './directives/ngx-responsive-column.directive';
import { DragDropDirective } from './directives/drag-drop.directive';

//Angular
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './auth/signup/signup.component';
import { BackOfficeLayoutComponent } from './backOffice/backOffice-layout/back-office-layout.component';
import { BackOfficeCampaignTrackingContainerComponent } from './backOffice/backOffice-campaign-tracking/backOffice-campaign-tracking-container/back-office-campaign-tracking-container.component';
import { BackOfficePublishContainerComponent } from './backOffice/backOffice-publish/back-office-publish-container/back-office-publish-container.component';
import { BackOfficeDashboardContainerComponent } from './backOffice/backOffice-dashboard/back-office-dashboard-container/back-office-dashboard-container.component';
import { ImagesRadiobuttonComponent } from './shared/components/images-radiobutton/images-radiobutton/images-radiobutton.component';
import { ImagesRadiobuttonItemComponent } from './shared/components/images-radiobutton/images-radiobutton-item/images-radiobutton-item.component';
import { MultiStatsBoardComponent } from './shared/components/multi-stats-board/multi-stats-board/multi-stats-board.component';
import { MultiStatsBoardItemComponent } from './shared/components/multi-stats-board/multi-stats-board-item/multi-stats-board-item.component';
import { CampaignSelectedComponent } from './backOffice/backOffice-campaign-tracking/components-tracking/campaign-selected/campaign-selected.component';
import { CampaignDatesComponent } from './backOffice/backOffice-campaign-tracking/components-tracking/campaign-dates/campaign-dates.component';
import { CampaignBudgetComponent } from './backOffice/backOffice-campaign-tracking/components-tracking/campaign-budget/campaign-budget.component';
import { HeatMapComponent } from './backOffice/backOffice-campaign-tracking/components-tracking/heat-map/heat-map.component';
import { BackOfficeAdvertisingManagementContainerComponent } from './backOffice/backOffice-advertising-management/back-office-advertising-management-container/back-office-advertising-management-container.component';
import { ImagesRadiobuttonItemImgComponent } from './shared/components/images-radiobutton/images-radiobutton-item-img/images-radiobutton-item-img.component';
import { DatesRangeComponent } from './shared/components/dates-range/dates-range.component';
import { TotalComponent } from './backOffice/backOffice-advertising-management/components-advertising-management/total/total.component';

import { BackOfficeAdminLayoutComponent } from './backOfficeAdmin/backOffice-admin-layout/back-office-admin-layout.component';
import { BackOfficeAdminCampaignsComponent } from './backOfficeAdmin/back-office-admin-campaigns/back-office-admin-campaigns.component';
import { BackOfficeAdminUsersComponent } from './backOfficeAdmin/backOffice-admin-users/back-office-admin-users.component';
import { FormModalComponent } from './shared/components/form-modal/form-modal.component';
import { PublishUploadComponent } from './backOffice/backOffice-publish/components-publish/publish-upload/publish-upload.component';
import { AppLoaderComponent } from './services/app-loader/app-loader.component';
import { BackOfficePublishUploadHistoryComponent } from './backOffice/backOffice-publish/back-office-publish-upload-history/back-office-publish-upload-history.component';
import { BackOfficeAdminUploadComponent } from './backOfficeAdmin/back-office-admin-upload/back-office-admin-upload.component';
import { BackOfficeAdminCampaignsTrackingComponent } from './backOfficeAdmin/back-office-admin-campaigns-tracking/back-office-admin-campaigns-tracking.component';
import { MiniStatsComponent } from './shared/components/mini-stats/mini-stats.component';
import { DownloadStatsComponent } from './backOffice/backOffice-dashboard/components-dashboard/download-stats/download-stats.component';
import { PageViewsStatsComponent } from './backOffice/backOffice-dashboard/components-dashboard/page-views-stats/page-views-stats.component';
import { MultiStatsComponent } from './backOffice/backOffice-dashboard/components-dashboard/multi-stats/multi-stats.component';
import { AudienceComponent } from './backOffice/backOffice-dashboard/components-dashboard/audience/audience.component';
import { BackOfficeAdminStatisticsComponent } from './backOfficeAdmin/back-office-admin-statistics/back-office-admin-statistics.component';
import { FormMainComponent } from './shared/components/form-main/form-main.component';
import { BackOfficeAdminCatalogComponent } from './backOfficeAdmin/back-office-admin-catalog/back-office-admin-catalog.component';
import { MapModalComponent } from './shared/components/map-modal/map-modal.component';
import { CampaignHistoryComponent } from './backOffice/backOffice-campaign-tracking/components-tracking/campaign-history/campaign-history.component';
import { TabSelectorComponent } from './backOffice/backOffice-dashboard/components-dashboard/tab-selector/tab-selector.component';
import { MiniStatsGbComponent } from './shared/components/mini-stats-gb/mini-stats-gb.component';
import { TopdaysComponent } from './backOffice/backOffice-dashboard/components-dashboard/topdays/topdays.component';
import { MultiStatsBoardCardItem } from './backOffice/backOffice-campaign-tracking/components-tracking/campaign-card-interBanner/campaign-interBanner.component';
import { DeviceComponent } from './backOffice/backOffice-advertising-management/components-advertising-management/device/device.component';
const config: InputFileConfig = { iconDelete: "cancel" };

@NgModule({
  declarations: [
    AppComponent,

    NgxResponsiveColumnDirective, DragDropDirective,

    removeExtPipe, PluralizePipe, ShortNumberPipe, DurationPipe,

    LoginComponent, ForgotComponent,
    ConfirmationModalComponent, AlertModalComponent, SignupComponent, BackOfficeLayoutComponent,
    BackOfficeCampaignTrackingContainerComponent, BackOfficePublishContainerComponent, BackOfficeDashboardContainerComponent,
    ImagesRadiobuttonComponent, ImagesRadiobuttonItemComponent, MultiStatsBoardComponent, MultiStatsBoardItemComponent, CampaignSelectedComponent, MultiStatsBoardCardItem,
    CampaignDatesComponent, CampaignBudgetComponent, HeatMapComponent, BackOfficeAdvertisingManagementContainerComponent, ImagesRadiobuttonItemImgComponent,
    DatesRangeComponent, TotalComponent, BackOfficeAdminLayoutComponent, BackOfficeAdminCampaignsComponent, BackOfficeAdminUsersComponent, FormModalComponent,
    PublishUploadComponent, AppLoaderComponent, BackOfficePublishUploadHistoryComponent, BackOfficeAdminUploadComponent, BackOfficeAdminCampaignsTrackingComponent,
    MiniStatsComponent, DownloadStatsComponent, PageViewsStatsComponent, MultiStatsComponent, AudienceComponent, BackOfficeAdminStatisticsComponent, FormMainComponent,
    BackOfficeAdminCatalogComponent,
    MapModalComponent,
    CampaignHistoryComponent,
    TabSelectorComponent,
    MiniStatsGbComponent,
    TopdaysComponent,
    SelectModalResponsablesComponent,
    DeviceComponent,
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LeafletModule.forRoot(),
    LeafletMarkerClusterModule,
    NgxDaterangepickerMd.forRoot(),
    InputFileModule.forRoot(config),
    //NgxMaskModule.forRoot(),
    AngularSvgIconModule,

    FormsModule, ReactiveFormsModule, FlexLayoutModule,

    //material
    MatProgressBarModule, MatInputModule, MatCardModule, MatSnackBarModule, MatButtonModule,
    MatIconModule, MatDialogModule, MatToolbarModule, MatSidenavModule, MatListModule, MatMenuModule,
    MatRadioModule, MatDividerModule, MatChipsModule, MatSlideToggleModule, MatCheckboxModule,
    MatProgressSpinnerModule, MatSelectModule, MatFormFieldModule, MatTabsModule,


    NgxSpinnerModule, Ng2FittextModule, NgxDatatableModule, MaterialFileInputModule, ChartsModule, FontAwesomeModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    OpenCatalogIdValidator,
    ThemeService
  ],
  entryComponents: [
    ConfirmationModalComponent, AlertModalComponent, FormModalComponent, AppLoaderComponent, MapModalComponent, SelectModalResponsablesComponent,
  ],

  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faApple, faAndroid, faTabletAlt);
  }
}
