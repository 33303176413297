import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch } from '../../helpers/formValidators';
import { DisableMatViewElements } from '../../helpers/DisableMatViewElements';
import { MatProgressBar, MatSnackBar, MatDialog } from '@angular/material';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { StateService } from '@uirouter/core';
import { AlertModalComponent } from '../../shared/components/alert-modal/alert-modal.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends DisableMatViewElements implements OnInit {
  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;

  signupForm: FormGroup;
  customText: any = window['customText'][window['language']];
  contactEmail: String = window['contactEmail'];
  contactPhone: String = window['contactPhone'];
  appUrl: String = window['appUrl'];

  isLoading: boolean = false;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    public stateService: StateService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.initFormGroup();

  }

  progressBarMode(mode) {
    if (this.progressBar && this.progressBar.mode)
      this.progressBar.mode = mode;
  }

  initFormGroup() {

    this.signupForm = this.formBuilder.group({

      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      login: ['', [Validators.required, Validators.email]],
      company: ['', Validators.required],
      position: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordCheck: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validator: MustMatch('password', 'passwordCheck')
    })

  }

  // convenience getter for Òeasy access to form fields
  get f() { return this.signupForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.signupForm.invalid)
      return;

    this.signup(this.signupForm.value);

  }

  signup(formData: FormData) {

    this.isLoading = true;
    this.disableAll();
    this.progressBarMode('indeterminate');
    this.authenticationService.signup(formData)
      .pipe(first())
      .subscribe(
        reponse => {
          this.isLoading = false;
          this.enableAll();
          this.progressBarMode('determinate');
          const dialogRef = this.dialog.open(AlertModalComponent, {
            maxWidth: '350px',
            data: {
              message: this.customText.signup_account_not_validated.text, btnOk: this.customText.signup_account_not_validated.btnOk,
              icon: { maticon: "warning", style: { 'color': 'indianred', 'height': '34px', 'width': '34px', 'font-size': '34px' } }
            }
          });


          this.stateService.go('login');

        },
        error => {
          this.isLoading = false;
          this.enableAll();
          this.progressBarMode('determinate');
          // this.snackBar.open(this.customText.snackBar_login_error.text, "", {
          //   duration: this.customText.snackBar_login_error.duration,
          //   verticalPosition: this.customText.snackBar_login_error.verticalPosition,
          //   horizontalPosition: this.customText.snackBar_login_error.horizontalPosition
          // });

        });
  }




}
