import { Component, OnInit, Input } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-campaign-interBanner',
    templateUrl: './campaign-interBanner.component.html',
    styleUrls: ['./campaign-interBanner.component.scss']
})

export class MultiStatsBoardCardItem implements OnInit {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() titleIcon: string;
    @Input() icon_color: string;
    @Input() value: number;
    @Input() type: string;
    @Input() impressionIcon: string;
    @Input() clickIcon: string;
    @Input() dialogClics: string;
    @Input() dialogImpression: string;
    @Input() bannerClics: string;
    @Input() bannerImpression: string;
    dynamicDataImpressions: number = 37.9;  // exemple de valeur
    dynamicDataClicks: number = 16.7;        // exemple de valeur
    @Input() clicsData: string;
    @Input() impressionData: string;

    watcher: Subscription;
    activeMediaQuery = '';

    constructor(private mediaObserver: MediaObserver) {
        this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
            this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';

            if (change.mqAlias == 'xs') {
            }
        });
    }

    setDefaultValues(): void {
        this.clicsData = this.clicsData || '0';
        this.impressionData = this.impressionData || '0';
    }

    ngOnInit(): void {
        this.setDefaultValues();
    }

}