import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ApiService } from "../../../services/api.service";
import { defaultAnimations } from "../../../shared/animations/default-animations";
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-back-office-publish-upload-history',
  templateUrl: './back-office-publish-upload-history.component.html',
  styleUrls: ['./back-office-publish-upload-history.component.scss'],
  animations: defaultAnimations
})
export class BackOfficePublishUploadHistoryComponent implements OnInit {
  @ViewChild('tableWrapper', { static: false }) tableWrapper;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  public readonly LAYOUT = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;
  customText: any = window['customText'][window['language']];
  public items: any[];
  private currentComponentWidth: number;
  public pageNumber: number = 0;
  public per_page: number = 5;
  public totalElements: number = 0;

  ngxDatatableMessage = {
    selectedMessage: 'selected',
    totalMessage: 'total',
    emptyMessage: `Aucun résultat`
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    setTimeout(() => {
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {

        if (this.items)
          this.items = [...this.items]
      }
    }, 200);
  }

  constructor(
    private apiService: ApiService,
    public changeDetectorRef: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver
  ) {
    this.generateBreakPoints();
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }


  onFileUploaded() {
    this.setPage({ offset: 0 });
  }


  campaignStatus(startCampaignDate: Date, endCampaignDate: Date) {
    const today = new Date();
    if (today > new Date(endCampaignDate))
      return {
        label: 'Terminé',
        color: '#7D8FA9'
      };
    if (today < new Date(startCampaignDate))
      return {
        label: 'Actif',
        color: '#28C76F;'
      };
    if (today >= new Date(startCampaignDate) && today <= new Date(endCampaignDate))
      return {
        label: 'En cours',
        color: '#FEAA02'
      };
  }




  generateBreakPoints() {
    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = (index > 0) ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) { return; }
          this.layout = maxWidth;

        });
    });
  }
  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getUploadUserHistory(this.pageNumber);
  }

  getUploadUserHistory(pageNumber) {

    this.apiService.getUploadUserHistory(pageNumber, 5)
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.totalElements = reponse['files']['totalCount'];
            this.items = reponse['files']['paginatedResults'];
            this.items = this.items.map(el => {
              el.status = this.campaignStatus(el.campainDateBegin, el.campaignDateEnd);
              return el;
            })
          }
        },
        error => {
          //this.loading = false;
        },
        () => {
          //this.loading = false;
        }
      );
  }

}
