import { Component, OnInit, Input } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';

@Component({
  selector: 'app-images-radiobutton-item',
  templateUrl: './images-radiobutton-item.component.html',
  styleUrls: ['./images-radiobutton-item.component.scss']
})
export class ImagesRadiobuttonItemComponent implements OnInit {
  @Input() label: String;
  @Input() value: String;
  @Input() image: String;
  @Input() selected: Boolean;
  constructor() { }

  ngOnInit() {
  }
}
