import { Component, OnInit, Input } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-campaign-dates',
  templateUrl: './campaign-dates.component.html',
  styleUrls: ['./campaign-dates.component.scss']
})
export class CampaignDatesComponent implements OnInit {
  @Input() dateBegin: Date;
  @Input() dateEnd: Date;


  constructor() { }

  ngOnInit() {
  }

}
