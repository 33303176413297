import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import * as moment from 'moment';
import * as inputmask from "inputmask";

@Component({
  selector: 'app-form-main',
  templateUrl: './form-main.component.html',
  styleUrls: ['./form-main.component.scss']
})
export class FormMainComponent implements OnInit {
  @Input() customForm: any;
  @Output() save = new EventEmitter<any>();

  form: FormGroup;
  inputs: Array<any>;
  submitted: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    //public dialogRef: MatDialogRef<FormMainComponent>,
    public dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.inputs = this.customForm.data.boxes;
    this.initFormGroup();
    this.checkItems(this.inputs);
  }

  ngAfterViewInit() {
    inputmask({ "insertMode": true, "clearIncomplete": true }).mask(document.querySelectorAll(".inputMask"));
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  initFormGroup(){
    let result = this.buildFormGroupWrapper(this.inputs);
    this.form = this.formBuilder.group(result);
  }

  checkItems(objects){
    for (let object of objects){
      if(object.type == 'slider' && object.disableOtherItemsOnState != undefined){
        this.changeElementsState(object, !(object.disableOtherItemsOnState == this.form.controls[object.name].value));
      }
    }
  }
  changeElementsState(itemSource, enable: boolean){
    for (let itemName in this.form.controls){
      if(itemSource.name != itemName){
        if(enable)
          this.form.controls[itemName].enable();
        else
          this.form.controls[itemName].disable();
      }
    }
  }

  buildFormGroupWrapper(boxes){
    let final = {};
    for (let box of boxes){
      let fg = this.buildFormGroup(box.inputs);
      final = { ...final, ...fg }
    }
    return final;
  }
  buildFormGroup(objects){
    let formBuild = {};

    for (let item of objects){
      let isArray = Array.isArray(item.data);

      if(!isArray)
        formBuild[item.name] = [ item.value, item.validators ];
      else
        formBuild = { ...formBuild, ...this.buildFormGroup(item.data) }
    }
    return formBuild;

  }

  onSubmit() {  
    this.submitted = true;
    if (this.form.invalid)
      return;
    
    this.save.emit(this.form.getRawValue());
    //this.dialogRef.close(this.form.getRawValue())
  }


  removeItem(items, item) {
    let i = items.indexOf(item);
    items.splice(i, 1);
    if (items)
      items = [...items]
  }

  onChange(event, item){

    if(item.type == 'slider' && item){

      if(item.alertOnChange && item.alertOnChange.onlyWhenChecked == event.checked && this.form.controls[item.alertOnChange.linkedTo].value == item.alertOnChange.displayIf){
        this.dialog.open(AlertModalComponent, {
          maxWidth: '350px',
          data: {
            message: item.alertOnChange.message, btnOk: item.alertOnChange.btnOk,
            icon: { maticon: "warning", style: { 'color': 'indianred', 'height': '34px', 'width': '34px', 'font-size': '34px' } }
          }
        });
      }

      if(item.disableOtherItemsOnState != undefined){
        this.changeElementsState(item, !(item.disableOtherItemsOnState == event.checked));
      }
    }

  }

  checkIfMomentInsideDateArray(dateArray, moment){
    if(dateArray && dateArray){
   
      for (let dates of dateArray){
        let dateA = dates.campainDateBegin;
        let dateB = dates.campaignDateEnd;

        let check = moment.isBetween(dateA, dateB, null, '[]' );
        if(check == true)
          return true;
      }
    }
    return false;
  }
  

  isInvalideDateWrapper(item: any) {
    return (m: moment.Moment) => { 
      return this.isInvalidDate(m, item) 
    };
  }

  isInvalidDate = (m: moment.Moment, item: any) =>  {
    
    if(item && item.invalideDates){

      let isInvalid = this.checkIfMomentInsideDateArray(item.invalideDates, m);
      if(item.invalideDatesLinkedTo && item.invalideDatesLinkedTo.input && item.invalideDatesLinkedTo.input.length &&
        this.form.controls[item.invalideDatesLinkedTo.input].value !=  item.invalideDatesLinkedTo.activeWhen){
          isInvalid = false;
      }

      return (!moment(new Date()).isSameOrBefore(m, "day") || isInvalid);
    } else 
      return false;
  }

}
