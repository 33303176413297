import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { User } from '../helpers/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private isLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLogged$: Observable<boolean> = this.isLogged.asObservable();

  apiUrl: string = "";
  secretEncryptionString: string = "3556749876543238";

  constructor(private http: HttpClient) {
    // @host
    // this.apiUrl = "http://localhost:4000"
    this.apiUrl = window['apiUrl'];
  }

  signup(formData: FormData) {
    return this.http.post<any>(this.apiUrl + "/api/auth/signup", formData)
      .pipe(map(reponse => {
        // login successful if there's a jwt token in the response
        return reponse;
      }));

  }

  login(email: string, password: string) {
    return this.http.post<any>(this.apiUrl + "/api/auth/login", { login: email, password: password })
      .pipe(map(reponse => {
        // login successful if there's a jwt token in the response
        if (reponse && reponse.error == false && reponse.user && reponse.token) {
          //for autologin
          this.saveUserLoginInformations(email, password);

          reponse.user['token'] = reponse.token;
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('ileco_current_user', JSON.stringify(reponse.user));
          this.isLogged.next(true);
        }
        return reponse;
      }));
  }

  resetPassword(email: string) {
    return this.http.post<any>(this.apiUrl + "/api/users/resetPassword", { email: email })
      .pipe(map(reponse => {
        console.log(reponse);
        return reponse;
      }));

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('ileco_current_user');
    this.isLogged.next(false);
  }

  async isAuthenticated() {
    let currentUser = localStorage.getItem('ileco_current_user');

    if (currentUser) {
      try {
        await this.http.get(this.apiUrl + "/api/auth/isLoggedIn").toPromise();
        return true;
      } catch (error) {
        return false;
      }
    }
    else
      return false;
  }

  getUserRole() {
    let currentUser = JSON.parse(localStorage.getItem('ileco_current_user'));
    if (currentUser)
      return currentUser['role'];
    else
      return "user"
  }

  async isAuthenticatedAdmin() {
    let auth = await this.isAuthenticated();

    if (!auth)
      return false;

    let currentUser = JSON.parse(localStorage.getItem('ileco_current_user'));
    return (currentUser['role'] == "admin");
  }

  async isAuthenticatedUser() {
    let auth = await this.isAuthenticated();

    if (!auth)
      return false;

    let currentUser = JSON.parse(localStorage.getItem('ileco_current_user'));
    return (currentUser['role'] == "user");
  }

  getCurrentUser() {
    let currentUser: User = JSON.parse(localStorage.getItem('ileco_current_user'));
    if (currentUser)
      return currentUser
    return null;
  }

  getCurrentUserId() {
    let currentUser = JSON.parse(localStorage.getItem('ileco_current_user'));
    if (currentUser) {
      return currentUser.userId;
    }
    return null;
  }

  //For autoLogin
  loginWithSavedInformations() {
    let loginInfo = this.getLoginFromLocalStorage();
    if (loginInfo && loginInfo.login.length && loginInfo.password.length) {
      return this.login(loginInfo.login, loginInfo.password);
    }
  }
  saveUserLoginInformations(login, password) {
    localStorage.setItem('loginInfo', JSON.stringify({
      login: login,
      password: this.encryptUsingAES256(password)
    }));
  }
  getLoginFromLocalStorage() {
    let loginInformations = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInformations && loginInformations.login && loginInformations.password)
      return {
        login: loginInformations.login,
        password: this.decryptUsingAES256(loginInformations.password)
      }
    return null;
  }
  encryptUsingAES256(stringToEncrypt: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.secretEncryptionString);
    let _iv = CryptoJS.enc.Utf8.parse(this.secretEncryptionString);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(stringToEncrypt), _key, {
      keySize: this.secretEncryptionString.length,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  decryptUsingAES256(stringToDecrypt: String) {
    let _key = CryptoJS.enc.Utf8.parse(this.secretEncryptionString);
    let _iv = CryptoJS.enc.Utf8.parse(this.secretEncryptionString);

    let decrypted = CryptoJS.AES.decrypt(
      stringToDecrypt.toString(), _key, {
      keySize: this.secretEncryptionString.length,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }

}
