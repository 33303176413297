import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

//Material
import { MatSnackBar, MatProgressBar, MatDialog } from '@angular/material';

import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../services/authentication.service';
import { StateService } from '@uirouter/core';
import { first } from 'rxjs/operators';
import { AlertModalComponent } from '../../shared/components/alert-modal/alert-modal.component';
import { DisableMatViewElements } from '../../helpers/DisableMatViewElements';

const CONSTANTS = require('../../../../../ileco-server/config/constants');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends DisableMatViewElements implements OnInit {

  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  loginForm: FormGroup;
  customText: any = window['customText'][window['language']];
  contactEmail: String = window['contactEmail'];
  contactPhone: String = window['contactPhone'];
  appUrl: String = window['appUrl'];
  isLoading: boolean = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    public stateService: StateService,
    public dialog: MatDialog,
  ) { super(); }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })

  }

  ngAfterViewInit() {
  }

  progressBarMode(mode) {
    if (this.progressBar && this.progressBar.mode)
      this.progressBar.mode = mode;
  }

  login(login: string, password: string) {

    this.isLoading = true;
    this.disableAll();
    this.progressBarMode('indeterminate');
    this.authenticationService.login(login, password)
      .pipe(first())
      .subscribe(
        reponse => {
          this.enableAll();
          this.progressBarMode('determinate');

          if (reponse && !reponse.error && reponse.user && reponse.user.role) {

            if (reponse.user.role == "user") {
              this.stateService.go('dashboard');
            }
            else if (reponse.user.role == "admin") {
              this.stateService.go('admin_users');
            }

            this.isLoading = false;
          }
          this.isLoading = false;
          //this.spinner.hide();
        },
        error => {
          console.log("error!", error.error);
          this.enableAll();
          this.progressBarMode('determinate');
          if (error && error.error && error.error.errorCode == CONSTANTS.SERVER_ACCOUNT_NOT_VALIDATED) {
            this.isLoading = false;
            const dialogRef = this.dialog.open(AlertModalComponent, {
              maxWidth: '350px',
              data: {
                message: this.customText.login_account_not_validated.text, btnOk: this.customText.login_account_not_validated.btnOk,
                icon: { maticon: "warning", style: { 'color': 'indianred', 'height': '34px', 'width': '34px', 'font-size': '34px' } }
              }
            });
          }
          this.snackBar.open(this.customText.snackBar_login_error.text, "", {
            duration: this.customText.snackBar_login_error.duration,
            verticalPosition: this.customText.snackBar_login_error.verticalPosition,
            horizontalPosition: this.customText.snackBar_login_error.horizontalPosition
          });
          this.isLoading = false;
        });
  }

  onSubmit() {

    this.submitted = true;
    if (this.loginForm.invalid)
      return;

    this.login(this.f.email.value, this.f.password.value);
  }

}
