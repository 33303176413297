import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Campain } from "../../../helpers/interfaces";
import { isEmptyObject } from "../../../helpers/func";
import { ShortNumberPipe } from "src/app/pipes/short-number.pipe";
import { ApiService } from "src/app/services/api.service";
import { MatSnackBar } from "@angular/material";
import { AppLoaderService } from "src/app/services/app-loader/app-loader.service";
import * as moment from "moment";

@Component({
  selector: "app-back-office-campaign-tracking-container",
  templateUrl: "./back-office-campaign-tracking-container.component.html",
  styleUrls: ["./back-office-campaign-tracking-container.component.scss"],
})
export class BackOfficeCampaignTrackingContainerComponent implements OnInit {
  @ViewChild("heatMap", { static: false }) heatMap;
  @Input() resolved;
  @Input() icon: string = "history.svg";
  isEmptyObject = isEmptyObject;

  customText: any = window["customText"][window["language"]];
  counties: any = this.customText.counties;
  sortedCounties: any[];
  countySelected: String = "mart";
  dialogData: any = {};
  bannerData: any = {};
  miniStatList: Array<any> = [];
  joomagDataByCatalogId: any = null;
  isLoading: boolean = true;
  secondHalfList: Array<any> = [];
  firstHalfList: Array<any> = [];
  campaign: Campain = {
    catalogOpened: null,
    title: "",
    image: "",
    dateBegin: new Date(),
    dateEnd: new Date(),
    price: 2299,
    status: "",
  };
  campaignDocuments: string[];

  constructor(private apiService: ApiService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    /*
    this.campaign.catalogOpenedPosition = this.resolved.campaignTracking.catalogOpenedPosition;
    this.campaign.image = this.resolved.campaignTracking.campaignImage;
    this.campaign.title =  this.resolved.campaignTracking.campaignName;
    this.campaign.dateBegin =  this.resolved.campaignTracking.campainDateBegin;
    this.campaign.dateEnd =  this.resolved.campaignTracking.campaignDateEnd;
    this.campaign.price =  this.resolved.campaignTracking.price;
    this.countySelected = this.resolved.campaignTracking.campaignCounty;
  
    if(this.resolved.campaignTracking.readingCount){
      this.statList.push(
        {
          title: "Nombre de lecture du catalogue",
          subtitle: "",
          icon_color: '#D4A8FF',
          icon: "icon_vues.svg",
          count: this.resolved.campaignTracking.readingCount,
          type: "number",
        }
      );
    }
  
    if(this.resolved.campaignTracking.timeSpent){
      this.statList.push(
        {
          title: "Temps moyen passé",
          subtitle: "",
          icon_color: '#FFC3B1',
          icon: "icon_stpwtch.svg",
          count: this.resolved.campaignTracking.timeSpent,
          type: "string",
        }
      );
    }
  
    if(this.resolved.campaignTracking.costByReading){
      this.statList.push(
        {
          title: "Coût par lectures",
          subtitle: "",
          icon_color: '#7AF388',
          icon: "euro_symbol",
          count: this.resolved.campaignTracking.costByReading + '€',
          type: "string",
        }
      );
    }
  
    if(this.resolved.campaignTracking.readRate){
      this.statList.push(
        {
          title: "Taux de lecture",
          subtitle: "",
          icon_color: '#E3F3AB',
          icon: "icon_stpwtch.svg",
          count: this.resolved.campaignTracking.readRate + '%',
          type: "string",
        }
      );
    }
  */
    this.sortCounty();
  }

  fetchJoomagStatisticsByDays(catalogId: string, dateBegin: string, dateEnd: string) {
    this.apiService.getJoomagStatisticsByCatalogPeriod(catalogId, dateBegin, dateEnd ).subscribe(
      (data) => {
        this.joomagDataByCatalogId = data;
        this.isLoading = false;
        setTimeout(() => {
          this.onImageRadioChangedEvent(this.countySelected);
        }, 0);
      },
      (error) => {
        console.error('Error fetching Joomag statistics:', error);
        this.isLoading = false;
        this.displayErrorSnackbar('Une erreur est survenue lors de la récupération des statistiques Joomag');
      }
    );
  }

  sortCounty() {
    const order = ['mart', 'guad', 'guya', 'maba'];
    this.sortedCounties = order.map(key => ({
      key,
      ...this.counties[key]
    }));
  }


  calculatePeriodeDate(selectedPeriod): any {

    let dateBegin: string = "";
    let dateEnd: string = "";
    let currentDate = moment(new Date());

    const words = selectedPeriod.split('-');
    if (!(words.length == 3 && words[0] == "period" && (words[1] == "month" || words[1] == "current")))
      return null;

    switch (words[1]) {

      case "month":
        if (words[2] == 0) {
          dateBegin = currentDate.clone().startOf('month').format('YYYY-MM-DD');
          dateEnd = currentDate.clone().format('YYYY-MM-DD');
        } else {
          let month = currentDate.clone().subtract(words[2], 'month');
          dateBegin = month.clone().startOf('month').format('YYYY-MM-DD');
          dateEnd = currentDate.clone().subtract(1, "month").endOf('month').format('YYYY-MM-DD');
        }

        break;

      case "current":
        let b = currentDate.clone().subtract(words[2], 'day');
        dateBegin = b.format('YYYY-MM-DD');
        dateEnd = currentDate.format('YYYY-MM-DD');
        break;
    }

    return { dateBegin, dateEnd }
  }

  ngAfterViewInit() {
    /*
    this.setCampaign(
      this.resolved.campaignTracking.catalogOpenedPosition,
      this.resolved.campaignTracking.campaignName,
      this.resolved.campaignTracking.campaignImage,
      this.resolved.campaignTracking.campainDateBegin,
      this.resolved.campaignTracking.campaignDateEnd,
      this.resolved.campaignTracking.price,
      this.resolved.campaignTracking.campaignCounty,
      this.resolved.campaignTracking.costByReading,
      this.resolved.campaignTracking.readingCount,
      this.resolved.campaignTracking.timeSpent,
      this.resolved.campaignTracking.readRate
    );*/
    //this.onImageRadioChangedEvent(this.countySelected);
  }

  onImageRadioChangedEvent(checkResult) {
    if (checkResult)
      this.heatMap.centerOnPoint(
        this.counties[checkResult].coords,
        this.counties[checkResult].zoom
      );
    //setTimeout(()=>{ this.heatMap.centerOnPoint(this.counties[checkResult].coords, this.counties[checkResult].zoom); }, 100);
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition,
    });
  }

  setCampaign(
    catalogOpened: any,
    title: string,
    image: string,
    dateBegin: Date,
    dateEnd: Date,
    price: number,
    countySelected: string,
    costByReading: number,
    readingCount: number,
    timeSpent: any,
    readRate: number,
    readerReturnRate: number,
    campaignDocuments: string[]
  ) {
    this.campaign.catalogOpened = catalogOpened;
    this.campaign.image = image;
    this.campaign.title = title;
    this.campaign.dateBegin = dateBegin;
    this.campaign.dateEnd = dateEnd;
    this.campaign.price = price;
    this.campaign.status = "status";
    this.countySelected = countySelected;
    this.campaignDocuments = campaignDocuments;
    this.firstHalfList = [];
    this.secondHalfList = [];

    let catalogId = catalogOpened.catalogId;
    if (!(dateBegin instanceof Date)) {
      dateBegin = new Date(dateBegin);
    }
    if (!(dateEnd instanceof Date)) {
      dateEnd = new Date(dateEnd);
    }
    let dateStartJoomag = dateBegin.toISOString().split("T")[0];
    let dateEndJoomag = dateEnd.toISOString().split("T")[0];
    let joomagApiErrorMessage =
      "Une erreur est survenue lors de la mise à jour des statistiques de Joomag";
    const addReadRateAndCostByReading = () => {
      // if (readRate || readRate === 0) {
      //   this.statList.push(
      //     {
      //       title: "Engagement (%)",
      //       subtitle: "",
      //       icon_color: 'transparent',
      //       icon: "engagement.svg",
      //       count: (new ShortNumberPipe().transform(readRate) ? new ShortNumberPipe().transform(readRate) : readRate) + '%',
      //       type: "string",
      //       color: "#489DEC"
      //     }
      //   );
      // }

      if (costByReading) {
        this.secondHalfList.push({
          title: "Coût par lecteur",
          subtitle: "",
          className: "costByReading",
          icon_color: "transparent",
          icon: "cout_lecteur.svg",
          count:
            (new ShortNumberPipe().transform(costByReading)
              ? new ShortNumberPipe().transform(costByReading)
              : costByReading) + "€",
          type: "string",
          color: "#FFFF",
        });
      }
    };
    this.isLoading = true;
    this.apiService
      .getJoomagStatistics(catalogId, dateStartJoomag, dateEndJoomag)
      .subscribe(
        (result) => {
          if (
            result &&
            result["data"] &&
            result["error"] == false &&
            result["data"]["error"] == 0
          ) {
            this.fetchJoomagStatisticsByDays(catalogId, dateStartJoomag, dateEndJoomag);
            // Joomag API call successful, now extract statistics and push to statList for displaying on the UI
            readingCount = result["data"]["data"]["unique_page_views"];
            timeSpent = result["data"]["data"]["avg-time-per-reader"];
            let mins = Math.floor(timeSpent / 60);
            let secs = timeSpent % 60;
            timeSpent = mins + "min " + secs + "s";
            readerReturnRate = result["data"]["data"]["returning-readers"] / 100;
            if (readingCount || readingCount === 0) {
              this.firstHalfList.push({
                title: "Total de lectures",
                subtitle: "",
                icon_color: "transparent",
                icon: "lectures.svg",
                className: "readingCount",
                count:
                  (new ShortNumberPipe().transform(readingCount)
                    ? new ShortNumberPipe().transform(readingCount)
                    : readingCount) + "",
                type: "string",
                color: "#FFFF",
              });
            }

            if (timeSpent) {
              this.secondHalfList.push({
                title: "Temps d'interaction",
                subtitle: "",
                icon_color: "transparent",
                icon: "interactions.svg",
                className: "timeSpent",
                count:
                  (new ShortNumberPipe().transform(timeSpent)
                    ? new ShortNumberPipe().transform(timeSpent)
                    : timeSpent) + "",
                type: "string",
                color: "#FFFF",
              });
            }

            if (readerReturnRate || readerReturnRate === 0) {
              this.firstHalfList.push({
                title: "Lecteurs de retour",
                subtitle: "",
                icon_color: "transparent",
                icon: "lectures_retour.svg",
                className: "readerReturnRate",
                count:
                  (new ShortNumberPipe().transform(readerReturnRate)
                    ? new ShortNumberPipe().transform(readerReturnRate)
                    : readerReturnRate) + "%",
                type: "string",
                color: "#FFFF",
              });
            }
            // update latest Joomag stats in database to maintain sync across the application (user + admin)
            let campaignTrackingId = catalogOpened._id;
            let stats = {
              readingCount: readingCount,
              timeSpent: timeSpent,
              readerReturnRate: readerReturnRate,
            };
            this.apiService
              .updateJoomagStatistics(campaignTrackingId, stats)
              .subscribe(
                (result) => {
                  if (!result || result["error"]) {
                    throw new Error("Error updating stats");
                  }
                },
                (error) => {
                  console.log("Error updating stats: ", error);
                }
              );
            // add read rate and cost by reading to statlist at the end for displaying in correct order on UI
            addReadRateAndCostByReading();
          } else {
            // read rate and cost by reading are not fetched from Joomag so must be displayed even if Joomag API call fails
            addReadRateAndCostByReading();
            this.displayErrorSnackbar(joomagApiErrorMessage);
          }
        },
        (error) => {
          // read rate and cost by reading are not fetched from Joomag so must be displayed even if Joomag API call fails
          addReadRateAndCostByReading();
          this.displayErrorSnackbar(joomagApiErrorMessage + " : " + error);
        }
      );
    this.onImageRadioChangedEvent(this.countySelected);
  }

  onCampaignSelected(selected: any) {
    console.log("selected: ", selected);

    this.dialogData.dialogClics = new ShortNumberPipe().transform(
      selected.dialogClics
    );
    this.dialogData.dialogImpression = new ShortNumberPipe().transform(
      selected.dialogImpression
    );

    this.bannerData.bannerClics = new ShortNumberPipe().transform(
      selected.bannerClics
    );
    this.bannerData.bannerImpression = new ShortNumberPipe().transform(
      selected.bannerImpression
    );

    this.setCampaign(
      selected,
      selected.campaignName,
      selected.campaignImage,
      selected.campainDateBegin,
      selected.campaignDateEnd,
      selected.price,
      selected.campaignCounty,
      selected.costByReading,
      selected.readingCount,
      selected.timeSpent,
      selected.readRate,
      selected.readerReturnRate,
      selected.campaignDocuments
    );
  }


  changeMiniStatsValue(name, value) {
    this.miniStatList.forEach(element => {
      if (element.name == name)
        element.count = value;
    });
  }

  calculateCount(gbArray) {
    let total = 0;
    gbArray.forEach(element => {
      total += element.counter;
    });
    return total;
  }



}
