import { Component, OnInit, Input, OnChanges, SimpleChange, ViewChild, ElementRef } from '@angular/core';
import '../../../../helpers/prototype';

@Component({
  selector: 'app-page-views-stats',
  templateUrl: './page-views-stats.component.html',
  styleUrls: ['./page-views-stats.component.scss']
})
export class PageViewsStatsComponent implements OnInit {
  @Input() pageViewsData: any = [];
  @Input() period: any;
  @Input() barColors: any;
  @ViewChild('barCanvasPageViews', { static: false }) canvas: ElementRef;

  constructor() { }

  sharedChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.3,
    legend: {
      display: false,
      position: 'bottom'
    }
  };
  /*
    chartColors: Array<any> = [{
      backgroundColor: '#00CDAE',
      borderColor: '#3f51b5',
      pointBackgroundColor: '#3f51b5',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }];*/

  barChartType = 'bar';
  barChartData: any[] = [{
    data: [],
    label: '',
    borderWidth: 0,
    backgroundColor: 'rgba(50, 198, 154, 1)',
    borderColor: '#32C69A',
    pointBackgroundColor: '#32C69A',
    pointBorderColor: '#fff',
    hoverBackgroundColor: 'rgba(50, 198, 154, 1.0)',
    hoverBorderColor: 'rgba(148,159,177,0.8)'
  }];
  barChartOptions: any = Object.assign({
    scaleShowVerticalLines: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.02)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        ticks: {
          maxTicksLimit: 5,
          fontSize: 10,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0
        },
        type: 'time',
        distribution: 'linear',
        time: {
          minUnit: 'day'
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.05)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        position: 'left',
        ticks: {
          callback: function (value, index, values) {
            return String(value).commarize();
          },
          beginAtZero: true,
          suggestedMax: 9,
          maxTicksLimit: 5
        }
      }]
    }
  }, this.sharedChartOptions);

  ngOnInit() {
    //Chart.pluginService.register(ChartAnnotation);
  }

  ngAfterViewInit(): void {
    this.refresh();
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (changes['pageViewsData'] && this.pageViewsData) {
      this.refresh();
    }
  }

  refresh() {
    this.setPageViewsTab(this.repackData(this.pageViewsData));
  }


  repackData(data) {
    let objects = {}
    data.forEach((element, index) => {
      if (!(index == data.length - 1 && element.counter == 0))
        objects[element.collected_at] = objects[element.collected_at] ? objects[element.collected_at] + element.counter : element.counter;
    });
    return objects;
  }

  setPageViewsTab(data) {

    if (data) {

      let backGroundColor: any = '#32C69A';

      if (this.canvas && this.barColors && this.barColors.length) {
        backGroundColor = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 100);
        this.barColors.forEach((color, index) => {
          backGroundColor.addColorStop(index, color);
        });
      }

      this.barChartData[0].data = [];
      this.barChartData[0].backgroundColor = backGroundColor;
      Object.entries(data).forEach(([key, value]) => {
        this.barChartData[0].data.push({ t: key, y: value });
      });
    }
  }
}
